<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          class="btn btn-secondary"
          @click="
            $router.push({
              name: 'sales-items-edit',
              params: {
                reference: sale.reference,
              },
            })
          "
        >
          Modifier les articles
        </button>
      </div>
    </div>
    <hr />

    <br />
    <div class="row align-items-end">
      <div class="col-3">
        <label for="">Référence </label>
        <input
          type="text"
          disabled
          v-model="sale.reference"
          class="form-control"
        />
      </div>

      <div class="col-4">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="sale.customer_reference"
          :disabled="!customer_change ? true : false"
          @change="customer_change = false"
          class="form-select"
        >
          <option value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="customer.fullName.includes('Bloq') ? { color: 'red' } : {}"
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div>

      <div class="col-2">
        <label for=""> </label>
        <input
          type="button"
          @click="
            customer_change
              ? (customer_change = false)
              : (customer_change = true)
          "
          :value="customer_change ? 'Désactiver' : 'Modifier'"
          class="form-control bg-warning"
        />
      </div>
      <div class="col-3">
        <label for="">Date <span class="text-danger">*</span></label>
        <input type="date" v-model="sale.date" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row align-items-end">
      <div class="col-2">
        <label for="">Total </label>
        <input
          type="text"
          :disabled="!amounts_change ? true : false"
          v-model="sale.totalPriceTTC"
          class="form-control"
        />
      </div>

      <div class="col-2">
        <label for="">Montant paye </label>
        <input
          type="text"
          :disabled="!amounts_change ? true : false"
          v-model="sale.amountPaid"
          class="form-control"
        />
      </div>

      <div class="col-2">
        <label for="">M. reste </label>
        <input
          type="text"
          :disabled="!amounts_change ? true : false"
          v-model="sale.amountUnpaid"
          class="form-control"
        />
      </div>

      <div class="col">
        <label for=""> </label>
        <input
          type="button"
          @click="
            amounts_change ? (amounts_change = false) : (amounts_change = true)
          "
          :value="amounts_change ? 'Désactiver' : 'Modifier les valeurs'"
          class="form-control bg-warning"
        />
      </div>
      <div class="col-3">
        <label for="">Échéance </label>
        <input type="date" v-model="sale.deadline" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Numéro du conteneur </label>
        <input
          type="text"
          v-model="sale.containerNumber"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for=""> Livré par </label>
        <select v-model="sale.delivery_reference" class="form-select">
          <option value="--">--</option>

          <option
            v-for="(employee, index) in employees.filter(
              (p) => p.fonction == 'delivery'
            )"
            :key="index++"
            :value="employee.reference"
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <label for=""> Remarque <span class="text-danger">*</span></label>
        <textarea
          type="date"
          v-model="sale.remark"
          class="form-control"
        ></textarea>
      </div>
    </div>

    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à la vente (Client,
            Quantité totale, Mantant totale, Nombre d'articles, ...) et
            correctes. <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-success"
          @click="save(sale)"
          :disabled="!accept ? true : false"
        >
          <i class="bi bi-check-square-fill"></i> Enregistrer les modifcations
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        (<span class="text-danger">*</span>) : Ce champ est obligatoire.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // sale: {},
      accept: false,
      customer_change: false,
      amounts_change: false,
    };
  },

  methods: {
    async save(data) {
      await this.$store.dispatch("sale/update", data);
      await setTimeout((this.accept = false), 2000);
    },
    async refresh() {
      this.$store.dispatch("sale/getAllItems");
    },
  },

  computed: {
    ...mapGetters("sale", {
      sale: "getSale",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("employee", {
      employees: "getAll",
    }),
  },

  mounted() {
    this.$store.dispatch("sale/show", this.$route.params.reference);
    this.$store.dispatch("customer/getAll");
    this.$store.dispatch("employee/getAll");
  },
};
</script>
